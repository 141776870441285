import { useState } from "react";
import { downloadReportCustomerStatus } from "../../api/finance";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useReportCustomerStatus } from "../../utils/finance/ReportBillingUtils";

export default function ReportCustomerStatus() {
  const { hasAccess } = useControlPermission();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const { projects, meta, loading, errorMsg } = useReportCustomerStatus();

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const onError = (message) => {
    setAlert({ show: true, message, type: "danger" });
  };

  return (
    <MainLayout resource_key="manage-customer">
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Laporan Jumlah Pelanggan</h1>
        <Box title="Laporan Status Pelanggan" loading={loading}>
          {hasAccess("export-customer-status-report") && (
            <div className="row mb-4">
              <div className="col-12 col-md-auto ml-auto">
                <ButtonDownloadReport onSuccess={onSuccess} onError={onError} />
              </div>
            </div>
          )}
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>Project/Grup</th>
                  <th>Mitra</th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Aktif
                  </th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Aktif Trial
                  </th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Isolir
                  </th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Dismantle
                  </th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Dismantle Trial
                  </th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Total Pelanggan
                  </th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Total Homepass
                  </th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Occupation Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project) => (
                  <tr key={project.id}>
                    <td>{project.project || "-"}</td>
                    <td>{project.partner || "-"}</td>
                    <td className="text-center">{project.active}</td>
                    <td className="text-center">{project.active_trial}</td>
                    <td className="text-center">{project.inactive}</td>
                    <td className="text-center">{project.dismantled}</td>
                    <td className="text-center">{project.dismantled_trial}</td>
                    <td className="text-center">{project.total}</td>
                    <td className="text-center">{project.homepass}</td>
                    <td className="text-center">{project.occupation_rate}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-right" colSpan={2}>
                    <b>Total</b>
                  </td>
                  <td className="text-center font-weight-bold">{meta.total_active}</td>
                  <td className="text-center font-weight-bold">{meta.total_active_trial}</td>
                  <td className="text-center font-weight-bold">{meta.total_inactive}</td>
                  <td className="text-center font-weight-bold">{meta.total_dismantled}</td>
                  <td className="text-center font-weight-bold">{meta.total_dismantled_trial}</td>
                  <td className="text-center font-weight-bold">{meta.total}</td>
                  <td className="text-center font-weight-bold">{meta.total_homepass}</td>
                  <td className="text-center font-weight-bold">{meta.total_occupation_rate}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Box>
      </div>
    </MainLayout>
  );
}

function ButtonDownloadReport({ onSuccess, onError }) {
  const [loading, setLoading] = useState(false);

  const downloadReport = async () => {
    setLoading(true);
    try {
      let response = await downloadReportCustomerStatus();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Laporan Jumlah Pelanggan per status per site.xlsx`);
      onSuccess(`Berhasil mengunduh Laporan Jumlah Pelanggan per status per site.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      onError(message);
    }

    setLoading(false);
  };

  return (
    <button className="btn btn-primary" onClick={() => downloadReport()} disabled={loading}>
      <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
    </button>
  );
}
