import { useState, useEffect } from "react";
import {
  getReportCustomerDue,
  getReportCustomerPayments,
  getReportCustomerStatus,
} from "../../api/finance";

export function useReportCustomerPayments({ filter }) {
  const [projects, setProjects] = useState([]);
  const [meta, setMeta] = useState({ total: 0 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getProjects = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getReportCustomerPayments({ ...filter });
      setProjects(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getProjects(filter);
  }, [filter]);

  return {
    projects,
    meta,
    loading,
    errorMsg,
    getProjects,
  };
}

export function useReportCustomerStatus() {
  const [projects, setProjects] = useState([]);
  const [meta, setMeta] = useState({
    total_inactive: 0,
    total_active: 0,
    total_dismantled: 0,
    total_active_trial: 0,
    total_dismantled_trial: 0,
    total: 0,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getProjects = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getReportCustomerStatus();
      setProjects(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return {
    projects,
    meta,
    loading,
    errorMsg,
    getProjects,
  };
}

export function useReportCustomerDue({ filter }) {
  const [projects, setProjects] = useState([]);
  const [meta, setMeta] = useState({ total: 0 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getProjects = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getReportCustomerDue(filter);
      setProjects(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getProjects(filter);
  }, [filter]);

  return {
    projects,
    meta,
    loading,
    errorMsg,
    getProjects,
  };
}
