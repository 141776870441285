import { useState } from "react";
import { downloadReportCustomerDue } from "../../api/finance";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useReportCustomerDue } from "../../utils/finance/ReportBillingUtils";

const objStatus = {
  0: "Isolir",
  1: "Aktif",
  3: "Aktif Trial",
};

export default function ReportCustomerTrial() {
  const { hasAccess } = useControlPermission();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [formFilter, setFormFilter] = useState({ is_active: 3 });
  const [filter, setFilter] = useState({ is_active: 3 });
  let { projects, meta, loading, errorMsg } = useReportCustomerDue({ filter });

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
    });
  };

  const resetFilter = () => {
    setFormFilter({ is_active: 3 });
    setFilter({ is_active: 3 });
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const onError = (message) => {
    setAlert({ show: true, message, type: "danger" });
  };

  return (
    <MainLayout resource_key="manage-customer">
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Laporan Jatuh Tempo</h1>
        <Box title="Laporan Pelanggan Jatuh Tempo" loading={loading}>
          <div className="row">
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Status Pelanggan</label>
                <select
                  className="form-control"
                  value={formFilter.is_active}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, is_active: event.target.value })
                  }
                >
                  <option value="1">Aktif</option>
                  <option value="0">Isolir</option>
                  <option value="3">Aktif Trial</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
            {hasAccess("export-customer-due-report") && (
              <div className="col-12 col-md-auto ml-auto" style={{ paddingTop: "32px" }}>
                <ButtonDownloadReport filter={filter} onSuccess={onSuccess} onError={onError} />
              </div>
            )}
          </div>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Jatuh Tempo</th>
                  <th>Jumlah Pelanggan</th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project) => (
                  <>
                    <tr key={project.id}>
                      <td colSpan={2} className="font-weight-bold table-primary">
                        {project.project || "-"}
                      </td>
                      <td className="font-weight-bold table-primary text-right">
                        {project.total || "-"}
                      </td>
                    </tr>
                    {project.plans.map((plan, index) => (
                      <tr key={index}>
                        <td></td>
                        <td>{plan.end_date}</td>
                        <td className="text-right">{plan.total}</td>
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="font-weight-bold text-right" colSpan={2}>
                    Total
                  </td>
                  <td className="font-weight-bold text-right">{meta.total}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Box>
      </div>
    </MainLayout>
  );
}

function ButtonDownloadReport({ filter, onSuccess, onError }) {
  const [loading, setLoading] = useState(false);

  const downloadReport = async () => {
    setLoading(true);
    try {
      let response = await downloadReportCustomerDue(filter);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Laporan Jatuh Tempo Pelanggan ${objStatus[filter.is_active]}.xlsx`
      );
      onSuccess(
        `Berhasil mengunduh Laporan Laporan Jatuh Tempo Pelanggan ${
          objStatus[filter.is_active]
        }.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      onError(message);
    }

    setLoading(false);
  };

  return (
    <button className="btn btn-primary" onClick={() => downloadReport()} disabled={loading}>
      <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
    </button>
  );
}
