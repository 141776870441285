import { useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import AlertBox from "../AlertBox";
import { useTicketbyIdHooks } from "../../utils/ticket/TicketUlits";
import { createTikcetDetail } from "../../api/ticket";
import ImageUploader from "../ImageUploader";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function ModalReportFollowupTicketing({ show, id, onClose, onSuccess }) {
  let { hasAccess } = useControlPermission();
  const { data } = useTicketbyIdHooks({ id });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);
  let [attachments, setAttachments] = useState([]);

  let [form, setForm] = useState({ description: "", role: "cs", resolved: "false" });

  const handleClose = () => {
    setAttachments([]);
    setAlert({ show: false, message: "", type: "" });
    setForm({ description: "", role: "cs", resolved: "false" });
    onClose();
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      role: form.role,
      description: form.description,
      resolved: form.resolved,
    };

    if (attachments.length > 0) {
      formData.attachments = [];
      attachments.forEach((attach) => {
        if (attach.attachment_url !== "") {
          formData.attachments.push({
            type: attach.type,
            attachment_url: attach.attachment_url,
          });
        }
      });
    }

    updateTicket(formData);
  };

  const updateTicket = async (form) => {
    setLoading(true);
    try {
      let response = await createTikcetDetail(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const onChangeImg = (type, image_url, index) => {
    let attachmentsData = [...attachments];
    attachmentsData[index].attachment_url = image_url;
    setAttachments([...attachmentsData]);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Detil Tiket - {data.ticket_number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Nomor</td>
                    <td>{data.ticket_number || "-"}</td>
                  </tr>
                  <tr>
                    <td>Tgl. Dibuat</td>
                    <td>
                      {data.createdAt
                        ? moment.unix(data.createdAt).format("DD MMMM YYYY - HH:mm")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Durasi</td>
                    <td>{data.total_time || "-"}</td>
                  </tr>
                  <tr>
                    <td>No. Pelanggan</td>
                    <td>{data.customer_number || "-"}</td>
                  </tr>
                  <tr>
                    <td>No. Registrasi</td>
                    <td>{data.registration_number || "-"}</td>
                  </tr>
                  <tr>
                    <td>Nama</td>
                    <td>{data.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>{data.address || "-"}</td>
                  </tr>
                  <tr>
                    <td>Telepon</td>
                    <td>{data.phone_number || "-"}</td>
                  </tr>
                  <tr>
                    <td>Prioritas</td>
                    <td>{data.priority || "-"}</td>
                  </tr>
                  <tr>
                    <td>Tipe</td>
                    <td>{data.type || "-"}</td>
                  </tr>
                  <tr>
                    <td>penugasan</td>
                    <td>{data.role || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-6">
              <h5>Detail Proses</h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  {data.ticket_details?.map((detail, index) => (
                    <tr key={index}>
                      <td>
                        {detail.status === "open" && (
                          <span className="badge badge-success">{detail.status}</span>
                        )}
                        {detail.status === "unresolved" && (
                          <span className="badge badge-warning">{detail.status}</span>
                        )}
                        {detail.status === "resolved" && (
                          <span className="badge badge-secondary">{detail.status}</span>
                        )}
                      </td>
                      <td>
                        <span className="text-sm mx-1">
                          <i className="fa fa-user"></i> {detail.worked_by || "-"}
                        </span>
                        <span className="text-sm mx-1">
                          <i className="far fa-clock"></i>{" "}
                          {moment.unix(detail.createdAt).format("DD MMMM YYYY - HH:mm")}
                        </span>

                        <span className="d-block my-3">
                          <span className="text-sm">Deskripsi:</span> <br />
                          {detail.description}
                        </span>
                        {detail.attachments.length > 0 && (
                          <>
                            <span className="text-sm">Gambar:</span> <br />
                            {detail.attachments.map((attach, index) => (
                              <a
                                className="d-block"
                                href={attach.attachment_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {attach.type}
                              </a>
                            ))}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h5>Form Laporan Tiket</h5>
              <AlertBox {...alert} setAlert={setAlert} />
              <div className="form-group">
                <label>Deskripsi</label>
                <textarea
                  className="form-control"
                  rows="5"
                  required
                  value={form.description}
                  onChange={(event) => setForm({ ...form, description: event.target.value })}
                ></textarea>
              </div>
              <div className="form-group">
                <label>Tugaskan ke:</label>
                <select
                  className="form-control"
                  required
                  value={form.role}
                  onChange={(event) => setForm({ ...form, role: event.target.value })}
                >
                  <option value="">-- Divisi --</option>
                  <option value="cs">CS</option>
                </select>
              </div>
              <div className="form-group">
                <label>Status Penyelesaian:</label>
                <select
                  className="form-control"
                  required
                  value={form.resolved}
                  onChange={(event) => setForm({ ...form, resolved: event.target.value })}
                >
                  <option value="false">Tidak terselesaikan</option>
                  <option value="true">Terselesaikan</option>
                </select>
              </div>
              {attachments.map((attach, index) => (
                <div className="form-group mb-4" key={index}>
                  <label>{attach.name}</label>
                  <ImageUploader
                    image={attach.attachment_url}
                    type={attach.type}
                    onChangeImg={(type, image_url) => onChangeImg(type, image_url, index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {(hasAccess("update-followup-ticket") || hasAccess("update-ticket")) && (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={() => handleClose()}
                className="btn btn-secondary mr-1"
              >
                Batal
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading || data.status === "closed"}
              >
                {loading ? "Menyimpan ... " : "Simpan"}
              </button>
            </div>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
}
